<template>
  <div class="content" style="color: #666">
    <div class="title">系统设置</div>

    <el-form
      ref="form"
      :model="form"
      label-width="200px"
      style="margin-left: 100px; margin-top: 30px"
    >
      <el-form-item style="width: 200px" label="闸门操作权限设置"></el-form-item>
      <el-form-item label="闸门操作权限人员">
        <el-select v-model="form.value1" multiple placeholder="请选择" style="width:30%">
          <el-option
            v-for="item in idList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="闸门操作设置">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">不启用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item style="margin-left:80px">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'systemsettings',
  data () {
    return {
      form: {
        status: '',
        value1: [],
      },
      value1: [],
      idList: [],
    }
  },
  mounted () {
    this.selectSysSettingsList()
    this.user()
  },
  methods: {
    onSubmit () {
      this.form.examinePerson = this.form.value1.join(',')

      this.$axios
        .post(`/sysSettings/updateSysSettings`, this.form)
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: '操作失败',
              type: 'warning'
            })
          }
        })
    },
    selectSysSettingsList () {
      this.$axios.get(`/sysSettings/selectSysSettingsList`).then((res) => {
        console.log(res.data)
        if (res.code == 200 && res.data[0]) {
          this.form.id = res.data[0].id
          this.form.status = res.data[0].status
          this.form.value1 = res.data[0].examinePerson.split(',')
        }
      })
    },
    // 用户
    user () {
      this.$axios.get(`/user/page/1/10`).then((res) => {
        console.log(res.data.list)

        res.data.list.forEach((v) => {
          console.log(v)
          let obj = {}
          obj.id = v.id + ''
          obj.userName = v.userName
          this.idList.push(obj)
        })
        console.log(this.idList)
      })
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .el-form-item__label {
  width: 200px !important;
  font-size: 20px;
}

.content {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .title {
    color: #666;
    width: 100%;
    height: 60px;
    background-color: #f9f9f9;
    display: flex;
    justify-content: start;
    align-content: center;
    line-height: 60px;
    padding-left: 30px;
    box-sizing: border-box;
  }
}
</style>
